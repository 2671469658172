import './App.css';
import React, { useState } from 'react';

const App = () => {
  const [question, setQuestion] = useState('');
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (question.trim() !== '') {
      setLoading(true);
      fetchStreamResponse(question); // Pass question as a parameter
    }
  };

  const fetchStreamResponse = async (question) => { // Accept question as a parameter
    const sourceId = process.env.REACT_APP_VERCEL_SOURCE_ID;
    const xApiKey = process.env.REACT_APP_VERCEL_X_API_KEY;

    const config = {
      headers: {
        'x-api-key': xApiKey,
        'Content-Type': 'application/json',
      },
      responseType: 'stream',
    };

    const data = {
      stream: true,
      sourceId: sourceId,
      messages: [
        {
          role: 'user',
          content: question,
        },
      ],
    };

    try {
      const response = await fetch('https://api.chatpdf.com/v1/chats/message', {
        method: 'POST',
        headers: config.headers,
        body: JSON.stringify(data),
      });

      const reader = response.body.getReader();
      let responseText = '';

      while (true) {
        const { done, value } = await reader.read();

        if (done) break;

        const chunk = new TextDecoder().decode(value);
        responseText += chunk;

        // Split response into separate lines based on newline character
        const lines = responseText.split('\n').filter(Boolean);
        setResponses(lines);
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
    setResponses([]); // Clear responses when question changes
  };

  return (
    <div className="container">
      {/* <h1>Scalora Consulting Group</h1> */}
      <div className="logo">
        <img src="https://static.wixstatic.com/media/c2d761_d4c9354d78074c0a9f79f44e13364a32~mv2.png/v1/fill/w_198,h_40,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ScaloraCG-Logo_WHITE_web.png" alt="Scalora Logo" />
      </div>
      <h3>Powered by Nicky AI - heynicky.com</h3>
      <div className="card">
        <input
          type="text"
          value={question}
          onChange={handleQuestionChange}
          placeholder="Type your question here"
        />
        <button onClick={handleSubmit} disabled={loading}>
          {loading ? 'Loading...' : 'Submit'}
        </button>
      </div>
      <div className="response-container">
        {responses.length > 0 && (
          <div>
            <h3>Response:</h3>
            {responses.map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
